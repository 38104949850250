import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react";
import BlockTitle from "../_BlockTitle";
import styles from "./styles/StaffBlock.module.scss"
import CleverSlider from "../../../utilities/CleverSlider/CleverSlider";
import StaffCard from "./StaffCard";
import {blockObjectFactory} from "../../SchoolBlocks/blockUtils";
import {StoreContext} from "../../../../stores/StoreLoader";
import {withoutLocaleInURI} from "../../../../internationalization/i18nURI";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";
import WatsonApi from "../../../../backends/WatsonApi";
import NotificationManager from "../../../notifications/NotificationManager";
import ViewMoreButton from "../ViewMoreButton";
import classNames from "classnames";

export const UserSlider = forwardRef<CleverSlider, {
    users: IUser[],
    handleNext?: () => void
        }>((props, ref) => {
            const {modalStore, interfaceStore} = useContext(StoreContext);
            const [disableArrowItems, setDisableArrowItems] = useState(4);

            useEffect(() => {
                if (interfaceStore.breakpoint === 'break-point-lg') {
                    setDisableArrowItems(4);
                }
                if (interfaceStore.breakpoint === 'break-point-md') {
                    setDisableArrowItems(3);
                }
                if (interfaceStore.breakpoint === 'break-point-sm') {
                    setDisableArrowItems(2);
                }
                if (interfaceStore.breakpoint === 'break-point-xs') {
                    setDisableArrowItems(5);
                }
            },[interfaceStore.breakpoint])

            const staffBlockSliderClassNames = classNames({
                [styles.staffBlockSlider4Items]: interfaceStore.breakpoint === 'break-point-lg',
                [styles.staffBlockSlider3Items]: interfaceStore.breakpoint === 'break-point-md',
                [styles.staffBlockSlider2Items]: interfaceStore.breakpoint === 'break-point-sm',
                [styles.staffBlockSliderMobile]: interfaceStore.breakpoint === 'break-point-xs',
            });

            return <CleverSlider
                ref={ref}
                totalSlides={props.users.length}
                sliderClassName={staffBlockSliderClassNames}
                containerClassName={styles.cleverSliderContainer}
                childSlideClassName={styles.childSlideContainer}
                trackClassName={styles.trackClassContainer}
                scrollArrowClassName={styles.scrollArrowClassName}
                disableArrowItems={disableArrowItems}
                height={260}
                handleNext={props.handleNext}
            >
                {props.users.map(staff => {
                    const modalObj = {
                        id: staff.id,
                        type: "schoolBlocksPerson",
                        blockObj: blockObjectFactory("person", {id: staff.id, blockModel: staff} as IPersonBlock),
                    }
                    return (
                        <button key={staff.id} onClick={() => modalStore.addModal(modalObj)}>
                            <div className={styles.slideItem} data-columns={(4)}>
                                <ul>
                                    <StaffCard staff={staff}></StaffCard>
                                </ul>
                            </div>
                        </button>
                    )
                })}
            </CleverSlider>
        }
        )

const StaffBlock = observer((props: {
    blockObj: IStaffTerrace,
    aboveTheFold: boolean,
}) => {
    const {modalStore, organizationStore, userStore} = useContext(StoreContext);
    const [viewMore, setViewMore] = useState(props.blockObj.staff_members.is_more);
    const [page, setPage] = useState(2);
    const [staffMembers, setStaffMembers] = useState(props.blockObj.staff_members.staff);
    const [searchTerm, setSearchTerm] = useState("");
    const cleverSliderRef = useRef<CleverSlider>(null);

    const fetchStaff = async () => {
        try {
            const q = {};
            let organizationPK = organizationStore.currentOrganization.id;
            if (organizationStore.currentOrganization.type === "school" ||
                organizationStore.currentOrganization.type === "district") {
                q["department"] = 'null';
            }
            else if (organizationStore.currentOrganization.type === "group" ||
                organizationStore.currentOrganization.type === "department" ||
                organizationStore.currentOrganization.type === "section" ||
                organizationStore.currentOrganization.type === "team") {
                organizationPK = organizationStore.organization.id;
                q["department_id"] = organizationStore.currentOrganization.id;
                q["type"] = 2;
            }
            const client = await WatsonApi();
            const resp = await client.apis.organizations.organizations_read_only_staff_member_list({
                organization_pk: organizationPK,
                page: page,
                page_size: props.blockObj.staff_members.page_size,
                deleted: "false",
                ...q,
                ordering: "profile__lname, profile__fname, profile__id",
            });
            setPage(v => v + 1);
            setStaffMembers(prevVals => [...prevVals, ...resp.obj.data]);
            setViewMore(!!resp.obj.links.next);
        } catch (e) {
            NotificationManager.error(e.message);
        }
    }

    const handleSearch = async () => {
        try {
            const q = {};
            let organizationPK = organizationStore.currentOrganization.id;

            if (organizationStore.currentOrganization.type === "school" ||
                organizationStore.currentOrganization.type === "district") {
                q["department"] = 'null';
            }
            else if (organizationStore.currentOrganization.type === "group" ||
                organizationStore.currentOrganization.type === "department" ||
                organizationStore.currentOrganization.type === "section" ||
                organizationStore.currentOrganization.type === "team") {
                organizationPK = organizationStore.organization.id;
                q["department_id"] = organizationStore.currentOrganization.id;
                q["type"] = 2;
            }
            const client = await WatsonApi();
            const resp = await client.apis.organizations.organizations_read_only_staff_member_list({
                organization_pk: organizationPK,
                search: searchTerm,
                ...q,
                deleted: "false",
                ordering: "profile__lname, profile__fname, profile__id",
            });
            if (cleverSliderRef.current) {
                cleverSliderRef.current.resetIndex(); //Reset index in CleverSlider so search results are not offscreen
            }
            setStaffMembers([...resp.obj.data]);
        } catch (e) {
            NotificationManager.error(e.message);
        }
    }

    function onClick () {
        modalStore.addModal({
            type: "syncGroupsModal",
        });
    }

    const staffBlockDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add Staff',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-plus"/>,
            onClick: onClick,
        },
    ]

    return (
        <article>
            <BlockTitle
                blockObj={props.blockObj}
                aboveTheFold={props.aboveTheFold}
                dropDownItems={(organizationStore.currentOrganization.type === 'department' ||
                    organizationStore.currentOrganization.type === 'group') && userStore.isAdmin && staffBlockDropDownItems}
                showViewMore={organizationStore.currentOrganization.type !== 'department'}
                viewMoreButtonUrl={withoutLocaleInURI("/staff")}
            />
            <div className={styles.container}>
                <div className={styles.searchContainer}>
                    <div>
                        <input
                            type="search"
                            className={styles.searchbar}
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            placeholder="Search Staff Members"
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    await handleSearch();
                                }
                            }}
                        />
                        <button onClick={handleSearch}>
                            <FontAwesome prefix={'fas'} name={'fa-search'}/>
                        </button>
                    </div>
                </div>
                {staffMembers?.length > 0 ? <UserSlider
                    ref={cleverSliderRef}
                    users={staffMembers}
                    handleNext={viewMore ? fetchStaff : undefined}
                /> : <div className={styles.noStaff}>No Staff Members found.</div>}
            </div>
            {organizationStore.currentOrganization.type !== 'department' && <div className={styles.viewMore}>
                <ViewMoreButton
                    url={withoutLocaleInURI("/staff")}
                />
            </div>}
        </article>
    );
})

export default StaffBlock;
